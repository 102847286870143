import * as React from 'react'
import { useCallback } from 'react'
import { ProductImage } from '../CategoryImage'
import useMediaQuery from '../../hooks/useMediaQuery'
import TailwindScreens, { TailwindScreensEM, TailwindScreensEMValues } from '../../helpers/tailwind'
import PropTypes from 'prop-types'
import { IconChevronLeft, IconChevronRight } from '../Icons'
import Slider from '../sliders/Slider'
import { thumbnailSizes } from '../../common/gatsbyImageData'
import { IconYouTubePlayBtn } from '../../icons/IconSocial'
import TangibleePlaceholderPure from '../products/TangibleePlaceholder'

const ImageGallerySliderItem = ({ children, onClick }) => {
  return (
    <div
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') onClick(e)
      }}
      role="button"
      tabIndex={0}
      aria-pressed="false"
      className="mx-2 aspect-square border-2 border-gray-200 duration-200 hover:border-b-2 hover:border-b-red-700 block cursor-pointer"
      style={{ userSelect: 'none' }}
    >
      {children}
    </div>
  )
}
const ImageGallerySliderButtonPrev = ({ onClick, disabled }) => {
  return (
    <button
      onClick={onClick}
      className={
        'text-2xl w-[2em] leading-none text-gray-400 hover:text-red-700 absolute left-0 h-full top-0 ' +
        (disabled ? 'hidden' : '')
      }
    >
      <IconChevronLeft />
    </button>
  )
}
const ImageGallerySliderButtonNext = ({ onClick, disabled }) => {
  return (
    <button
      onClick={onClick}
      className={
        'text-2xl w-[2em] leading-none text-gray-400 hover:text-red-700 absolute right-0 h-full top-0 ' +
        (disabled ? 'hidden' : '')
      }
    >
      <IconChevronRight />
    </button>
  )
}

const ImageGallerySliderItemsPerScreen = () => {
  const isXs = useMediaQuery(TailwindScreens.xs)
  const isMd = useMediaQuery(TailwindScreens.md)
  const isLg = useMediaQuery(TailwindScreens.lg)
  return [
    useCallback(() => {
      if (isLg) return 6
      if (isMd) return 3
      if (isXs) return 4
      return 3
    }, [isLg, isMd, isXs]),
    'basis-1/3 xs:basis-1/4 md:basis-1/3 lg:basis-1/6',
  ]
}

const ImageGallerySlider = ({ product, videos, slides, onSlideChange }) => {
  const sizes = `${TailwindScreensEM.xs} 33.3333vw, ${TailwindScreensEM.md} 25vw, ${
    TailwindScreensEM.lg
  } ${(100 / 12).toFixed(4)}vw, ${TailwindScreensEM.container} ${(
    TailwindScreensEMValues.container / 12
  ).toFixed(4)}em, 50vw`
  const videosCount = (videos || []).length

  const memoizedChildren = React. useMemo(() => {
    return [...(videos||[]), ...slides].map((slide, index) => {
      const isVideo = index < videosCount
      return (
        <ImageGallerySliderItem
          key={'image-' + index}
          onClick={e => {
            e.preventDefault()
            if (onSlideChange)
              onSlideChange({
                slide,
                index: isVideo ? index : index - videosCount,
                type: isVideo ? 'video' : 'image',
              })
          }}
        >
          {isVideo ? (
            <div className="relative w-full h-full">
              {slide.thumbnail && (
                <ProductImage
                  product={product}
                  image={slide}
                  sizes={sizes}
                  mockSizes={thumbnailSizes}
                />
              )}
              <IconYouTubePlayBtn className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 size-12 text-red-600" />
            </div>
          ) : slide.type === 'tangiblee' ? (
            <TangibleePlaceholderPure image={slide} type="small" />
          ) : (
            <ProductImage
              product={product}
              image={slide}
              sizes={sizes}
              mockSizes={thumbnailSizes}
            />
          )}
        </ImageGallerySliderItem>
      )
    })
  }, [videos, slides, videosCount, product, sizes, onSlideChange])

  return (
    <Slider
      className={'px-[2em]'}
      buttonPrev={ImageGallerySliderButtonPrev}
      buttonNext={ImageGallerySliderButtonNext}
      childrenClasses="flex-shrink-0"
      itemsPerScreenFactory={ImageGallerySliderItemsPerScreen}
    >
      {memoizedChildren}
    </Slider>
  )
}
ImageGallerySliderButtonPrev.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}
ImageGallerySliderButtonNext.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}
ImageGallerySliderItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  onClick: PropTypes.func.isRequired,
}

ImageGallerySlider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.object).isRequired,
  videos: PropTypes.arrayOf(PropTypes.object),
  product: PropTypes.object.isRequired,
  onSlideChange: PropTypes.func,
}

export default ImageGallerySlider
